import { ContextConfig, NavManagerContextProvider, ReactRootInitConfig } from '@nab/x-spa-react';

import { configMgr } from 'lib/configMgr';
import { httpClient } from 'lib/httpClient';

import { constants } from 'tenants/constants';

import { IHeaderConfig, TenantCore } from 'tenants/_strategy';

import { headerConfig, initConfig } from '../configs';

export class Core extends TenantCore {
  public override async rootInitConfig(): Promise<ReactRootInitConfig> {
    return await initConfig();
  }

  public override headerConfig(): IHeaderConfig {
    return headerConfig;
  }

  public override async spaContextProvider(contextId: string): Promise<ContextConfig> {
    try {
      if (contextId === constants.ar.ROOT_SPA) {
        const navmanagerUrl = configMgr.get('NAV_MANAGER_BASE_URL');
        /**
         * @TODO - Uplift Nav Manager to accept a context id string instead of passing a database UUID
         */
        let contexts: ContextConfig[];
        try {
          contexts = await new NavManagerContextProvider(navmanagerUrl).getContexts([
            configMgr.get('NAV_MANAGER_CONTEXT_ID')
          ]);
        } catch (e) {
          throw new Error('Failed to get menus');
        }

        const rootContext = contexts.find(context => context.id === constants.ar.ROOT_SPA);

        return rootContext;
      } else {
        const tenant = configMgr.get('TENANCY_NAME').toLowerCase();

        const resp = await httpClient.get(`config/${tenant}/${configMgr.get('ENVIRONMENT')}/context-${contextId}.json`);

        return resp.data;
      }
    } catch (e) {
      throw new Error(`Failed to retrieve context ${contextId}\n${e}`);
    }
  }
}
