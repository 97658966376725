import { Header, HeaderEventName, MenuItemType } from '@nab/nui-react-molecule';
import { useActions, MenuNode, useUser } from '@nab/x-spa-react';

import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import Star from 'assets/images/star.svg';
import StarMore from 'assets/images/star_more.svg';

import { useLogoutCleanup } from 'hooks/useLogout';

import { setFocus } from 'helpers/setFocus';

import { Tenant } from 'tenants/_strategy';
import { ActiveAppInstance } from 'tenants/types';

export interface GlobalHeaderProps {
  activeAppInstance?: ActiveAppInstance;
  mainRef?: MutableRefObject<any>;
  navMgrMenu?: MenuNode[];
  isAuthenticated?: boolean;
}

const GlobalHeader: React.FC<GlobalHeaderProps> = ({
  activeAppInstance,
  mainRef,
  navMgrMenu,
  isAuthenticated = false
}) => {
  const tenant = Tenant.instance();
  const user = useUser();
  const headerRef = useRef(null);
  const { addEventListener, removeEventListener, navigate } = useActions();
  const { logoutUser } = useLogoutCleanup();

  const handleSkipToMainContent = useCallback(() => {
    const appId = activeAppInstance?.app?.id;
    if (appId) {
      setFocus(mainRef, activeAppInstance?.app?.id, true);
    }
  }, [mainRef, activeAppInstance]);

  const headerConfig = useMemo(() => {
    return tenant.core.headerConfig();
  }, [tenant.core]);

  const onNavigate = useCallback(
    (item: MenuNode) => {
      navigate(`${activeAppInstance?.route?.parentPath}${item.route}`);
    },
    [activeAppInstance?.route?.parentPath, navigate]
  );

  const location = window.location;

  useEffect(() => {
    const title = activeAppInstance?.app?.title;
    window.document.title = (title ? `${title} - ` : '') + headerConfig.headerTitle.title;
  }, [activeAppInstance, headerConfig.headerTitle.title]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addEventListener(HeaderEventName.LOGOUT, event =>
      Tenant.instance().events.handleLogoutButton(logoutUser)
    );

    addEventListener(HeaderEventName.SKIP_TO_MAIN_CONTENT, handleSkipToMainContent);
    return () => {
      removeEventListener(HeaderEventName.LOGOUT);
      removeEventListener(HeaderEventName.SKIP_TO_MAIN_CONTENT);
    };
  }, [addEventListener, handleSkipToMainContent, logoutUser, removeEventListener, user]);

  return (
    <Header
      // @ts-ignore
      history={{ location }}
      mainRef={mainRef}
      headerRef={headerRef}
      useActions={useActions}
      isAuthenticated={isAuthenticated}
      isHamburgerButtonVisible
      onNavigate={onNavigate}
      navMgrMenu={navMgrMenu}
      rootPath={activeAppInstance?.route?.parentPath || ''}
      brandConfig={{
        desktopLogo: StarMore,
        mobileLogo: Star,
        alt: headerConfig.headerTitle.alt,
        title: headerConfig.headerTitle.title
      }}
      skipToMainContentConfig={{
        title: 'Skip to main content',
        type: MenuItemType.EVENT_TRIGGER,
        eventName: HeaderEventName.SKIP_TO_MAIN_CONTENT,
        displayTitle: true,
        displayOnSmScreen: true,
        display: false
      }}
      profileSwitcherConfig={null}
      preLoginMenu={headerConfig.preLoginItems}
      postLoginMenu={headerConfig.postLoginItems}
    />
  );
};

export default GlobalHeader;
