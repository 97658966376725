import Cookies from 'js-cookie';
import { ContextConfig, ReactRootInitConfig } from '@nab/x-spa-react';

import { configMgr } from 'lib/configMgr';
import { httpClient } from 'lib/httpClient';
import { WIDGET_NAME } from 'tenants/types';

import { constants } from 'tenants/constants';

import { TenantCore, IHeaderConfig } from 'tenants/_strategy';

import { initConfig, headerConfig } from '../configs';
import { NavManagerContextProviderExtended } from '../nav-manager/navManagerProviderExtended';

export class Core extends TenantCore {
  public override async rootInitConfig(): Promise<ReactRootInitConfig> {
    return await initConfig();
  }

  public override headerConfig(): IHeaderConfig {
    return headerConfig;
  }

  public override async spaContextProvider(contextId: string): Promise<ContextConfig> {
    try {
      if (contextId === constants.ib.ROOT_SPA) {
        const navmanagerUrl = configMgr.get('NAV_MANAGER_BASE_URL');
        const contexts: ContextConfig[] = await new NavManagerContextProviderExtended(navmanagerUrl).getContexts(
          navmanagerUrl
        );

        const rootContext = contexts.find(context => context.id === constants.ib.ROOT_SPA);
        return rootContext;
      } else {
        const tenant = configMgr.get('TENANCY_NAME').toLowerCase();
        const resp = await httpClient.get(`config/${tenant}/${configMgr.get('ENVIRONMENT')}/context-${contextId}.json`);

        if (contextId === WIDGET_NAME.LINKAGE_MINIAPP) {
          resp.data?.apps.forEach((app: Record<string, Record<string, string>>) => {
            app.appConfig = {
              ...app?.appConfig,
              nabId: JSON.parse(Cookies.get(constants.ib.IDENTITY_INFO) || '{}')?.username
            };
          });
        }
        return resp.data;
      }
    } catch (e) {
      throw new Error(`Failed to retrieve context ${contextId}\n${e}`);
    }
  }
}
