import { MenuNode } from '@nab/x-spa-react';
/*
 * Get title from menu's item
 * @param {Array} menu items
 * @param {String} route path
 * @returns {String} returns the title of menu item.
 */
export function getTitleFromMenuItems(menu: MenuNode[] = [], route: string) {
  const currentItem = menu.find(item => item.route === route);
  const { label } = currentItem;
  return label;
}
