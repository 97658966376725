import { AppDRUMStrategy, DigitalDataStrategy, LaunchDarklyFlagStrategy } from '@nab/nab-x-sdk-browser';
import {
  DafAnonymousAuthStrategy,
  DafInMemoryCacheStrategy,
  DefaultAuthConfig,
  ReactRootInitConfig
} from '@nab/x-spa-react';
import { Tenant } from 'tenants/_strategy';
import { AuthConfigs } from 'lib/auth-strategies/auth.config';
import { configMgr } from 'lib/configMgr';

import GlobalSpa from 'components/GlobalSpa/GlobalSpa';
import { IbAuthStrategy } from 'lib/auth-strategies/ib-auth-strategy';
import { IBDafExchangeStrategy } from 'lib/auth-strategies/ib-daf-exchange-strategy';

import { constants } from 'tenants/constants';

import { IBBrandConfigWithDafTenancy } from '../types';

export const initConfig = async (): Promise<ReactRootInitConfig> => {
  Tenant.instance().session.clear();

  const isAuthenticated = Tenant.instance().session.isValid();
  const userId = Tenant.instance().session.userId();

  const environment = configMgr.get('ENVIRONMENT'); // 'prod', 'sit1', 'dev', 'local' etc

  const authConfigs = AuthConfigs.getInstance({
    environment,
    clientId: configMgr.get('DAF_AUTH_CLIENT_ID'),
    scopes: configMgr.get('DAF_AUTH_SCOPE')
  });

  const dafAuthUrl = `${configMgr.get('DAF_ENDPOINT')}/v1/idp/oauth/token`;

  const customerSessionID = window.sessionStorage.getItem(constants.ib.CUSTOMER_SESSION_ID);

  return {
    // React component which provides the main layout for the shell
    rootComponent: GlobalSpa,

    // Named set of apps and menu items which live in this shell
    rootContextId: 'root',

    // Default auth config inherited by all apps which don't explicitly override it
    authConfig: isAuthenticated
      ? (authConfigs.postLoginAuthConfig as DefaultAuthConfig)
      : (authConfigs.preLoginAuthConfig as DefaultAuthConfig),

    authStrategies: [
      new IbAuthStrategy(authConfigs.postLoginAuthConfig, isAuthenticated),
      new DafAnonymousAuthStrategy(dafAuthUrl, authConfigs.clientId),
      new IBDafExchangeStrategy(dafAuthUrl)
    ],

    // Defines how tokens are cached & validated
    authTokenCacheStrategy: new DafInMemoryCacheStrategy(),

    // Defines how context config is retrieved (eg. from an API, JSON file on disk etc...)
    spaContextProvider: Tenant.instance().core.spaContextProvider,

    userConfig: {
      authenticated: isAuthenticated,
      id: userId,
      org: 'NAB',
      type: 'customer',
      sessionId: customerSessionID ?? null,
      context: {
        shell: constants.common.UNIVERSAL_SHELL
      }
    },

    servicesConfig: {
      rum: {
        strategy: new AppDRUMStrategy({
          libraryUrl: configMgr.get('APPDRUM_SCRIPT_TAG_SRC'),
          configValue: {
            appKey: configMgr.get('APPDRUM_APP_KEY'),
            adrumExtUrlHttp: configMgr.get('APPDRUM_EXT_URL_HTTP'),
            adrumExtUrlHttps: configMgr.get('APPDRUM_EXT_URL_HTTPS'),
            beaconUrlHttp: configMgr.get('APPDRUM_BEACON_URL_HTTP'),
            beaconUrlHttps: configMgr.get('APPDRUM_BEACON_URL_HTTPS'),
            xd: {
              enable: true
            },
            spa: {
              spa2: true
            }
          }
        })
      },

      analytics: {
        strategy: new DigitalDataStrategy()
      },

      flags: {
        // IB LaunchDarkly Key
        strategy: new LaunchDarklyFlagStrategy(configMgr.get('LAUNCH_DARKLY_API_KEY', 'not-set!!!')),
        apiKey: '',
        initialData: undefined
      },

      dataSecurity: {
        gbd: {
          LibraryUrl: configMgr.get('GBD_URL')
        },
        bc: {
          LibraryUrl: configMgr.get('BIOCATCH_URL'),
          configValue: {
            customerBrand: constants.ib.BC_BRAND_VALUE
          }
        },
        ...(environment !== 'local' && {
          fhp: {
            LibraryUrl: configMgr.get('FHP_LIBRARY_URL'),
            configValue: {
              backUrl: configMgr.get('FHP_BACK_URL'),
              cid: configMgr.get('FHP_CHANNEL_ID')
            }
          }
        })
      },

      interaction: {
        endpoint: `${configMgr.get('KONG_DOMAIN')}/v1/rt-trace/interactions`,
        sessionEndpoint: `${configMgr.get('KONG_DOMAIN')}/v1/rt-trace/session`
      },

      interactionPrefix: constants.ib.INTERACTION_PREFIX
    },

    // Defines brandId and themeName, and miniapps will read it.
    brand: {
      id: constants.common.IB_TENANT,
      themeName: constants.common.NAB,
      dafTenancy: constants.common.IB_TENANT
    } as IBBrandConfigWithDafTenancy,

    // Where should the shell be rendered?
    hostElement: document.querySelector('#appRoot')
  };
};
