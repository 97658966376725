import { ContextConfig, ReactRootInitConfig, NavManagerContextProvider } from '@nab/x-spa-react';

import { configMgr } from 'lib/configMgr';
import { httpClient } from 'lib/httpClient';
import { MINIAPP_SPA, CustomSpaAppLoaderConfig } from 'tenants/types';
import { getTitleFromMenuItems } from 'helpers/menu';

import { constants } from 'tenants/constants';

import { TenantCore, IHeaderConfig } from 'tenants/_strategy';

import { initConfig, headerConfig } from '../configs';

export class Core extends TenantCore {
  public override async rootInitConfig(): Promise<ReactRootInitConfig> {
    return await initConfig();
  }

  public override headerConfig(): IHeaderConfig {
    return headerConfig;
  }

  public override async spaContextProvider(contextId: string): Promise<ContextConfig> {
    try {
      if (contextId === constants.npp.ROOT_SPA) {
        const navmanagerUrl = configMgr.get('NAV_MANAGER_BASE_URL');
        /**
         * @TODO - Uplift Nav Manager to accept a context id string instead of passing a database UUID
         */
        let contexts: ContextConfig[];
        try {
          contexts = await new NavManagerContextProvider(navmanagerUrl).getContexts([
            configMgr.get('NAV_MANAGER_CONTEXT_ID')
          ]);
        } catch (e) {
          throw new Error('Failed to get menus');
        }

        /**
         * @TODO - Why do we need to do this?
         * We need to set the context id to the SPA name or else Megamenu won't load.
         * Can we moved this logic to the NavManagerContextProvider in the SDK instead?
         * */
        contexts.forEach(context => {
          context.id = MINIAPP_SPA.PORTAL_PAY;
          context.apps.forEach(app => {
            const customSpaAppLoaderConfig = app as CustomSpaAppLoaderConfig;
            const { appMetaData } = customSpaAppLoaderConfig;

            appMetaData.title = getTitleFromMenuItems(context.menu, appMetaData?.route);

            if ('shellConfig' in app) {
              app.appConfig = { ...app.appConfig, shellConfig: app.shellConfig };
            }
            return app;
          });
          return context;
        });

        const rootContext = contexts.find(context => context.id === constants.npp.ROOT_SPA);

        return rootContext;
      } else {
        const tenant = configMgr.get('TENANCY_NAME').toLowerCase();

        const resp = await httpClient.get(`config/${tenant}/${configMgr.get('ENVIRONMENT')}/context-${contextId}.json`);

        return resp.data;
      }
    } catch (e) {
      throw new Error(`Failed to retrieve context ${contextId}\n${e}`);
    }
  }
}
