import { IHeaderConfig } from 'tenants/_strategy';

export const headerConfig: IHeaderConfig = {
  headerTitle: {
    alt: 'NAB Adviser Registration',
    title: 'NAB Adviser Registration'
  },
  preLoginItems: [],
  postLoginItems: []
};
