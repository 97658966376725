import { getShellActions } from '@nab/x-spa-react';
import Cookies from 'js-cookie';
import { AuthConfigs } from 'lib/auth-strategies/auth.config';
import { configMgr } from 'lib/configMgr';
import { httpClient } from 'lib/httpClient';
import { TenantSession } from 'tenants/_strategy';
import { constants } from 'tenants/constants';

export class Session extends TenantSession {
  public override async revoke(currentToken?: string): Promise<void> {
    const token = currentToken || Cookies.get(constants.ar.USL_SESSION_COOKIE_ID);

    if (token) {
      const requestUrl = configMgr.get('KONG_DOMAIN') + configMgr.get('REVOKE_TOKEN_PATH');

      await httpClient.post(
        requestUrl,
        {
          client_id: configMgr.get('AUTH_CLIENT_ID'),
          token,
          token_type_hint: constants.ar.TOKEN_TYPE_HINT
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          'axios-retry': {
            retries: 2,
            retryCondition: error => error?.response?.status !== 200
          }
        }
      );
    }
  }

  public override async clear(): Promise<void> {
    // Clearing session for Identity Miniapp
    sessionStorage.removeItem(constants.common.BEARER_TOKEN);

    //clearSessionToken
    Cookies.remove(constants.ar.USL_SESSION_COOKIE_IS_AUTHENTICATED);
    Cookies.remove(constants.ar.USL_SESSION_COOKIE_ID);

    const { updateApps } = getShellActions();
    const authConfig = AuthConfigs.getInstance().preLoginAuthConfig;

    await updateApps({
      authConfig
    });
  }

  public override token(): string {
    const token: string | null = Cookies.get(constants.npp.USL_SESSION_COOKIE_ID);

    return typeof token === 'string' ? `Bearer ${token}` : '';
  }

  public override isValid(): boolean {
    const sessionCookie = Cookies.get(constants.ar.USL_SESSION_COOKIE_ID);
    const isAuthenticated = Cookies.get(constants.ar.USL_SESSION_COOKIE_IS_AUTHENTICATED);

    return isAuthenticated === 'true' && typeof sessionCookie === 'string';
  }

  public override userId(): string {
    return '';
  }
}
