/* eslint-disable @typescript-eslint/no-unused-vars */
import { ITenantSession } from 'tenants/_strategy/lib/types';

export class TenantSession implements ITenantSession {
  public clear(): void {
    throw new Error('Method not implemented.');
  }

  public revoke(csid: string, currentToken?: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  public token(): string {
    throw new Error('Method not implemented.');
  }

  public isValid(): boolean {
    throw new Error('Method not implemented.');
  }

  public userId(): string {
    return '';
  }
}
