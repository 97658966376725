import { AppInstance, RouteError, ContextConfig, XV2App, SpaAppLoaderConfig } from '@nab/x-spa-react';
import { AppActions } from '@nab/x-types';

export interface IState {
  isAuthenticated: boolean;
  userId: string;
}

export type LoginParams = {
  Authorization: string;
  browserData: string;
  customerSessionId: string;
};

export enum EVENTS {
  ANALYTICS_EVENT_NAME = 'ANALYTICS',
  LOGOUT = 'LOGOUT',
  GLOBAL_ERROR_EVENT_NAME = 'GLOBAL_ERROR',
  USER_AUTHENTICATED = 'user_authenticated',
  CHAT_LOGOUT = 'nab-chat-widget:logout'
}

export enum CLASSIC_ERROR_CODES {
  DEFAULT_ERROR = '0',
  UNAUTHORISED = '201029',
  UNAUTHORISED_TO_ENSURE_SECURITY = '201030',
  RESET_PASSWORD = '15502'
}
export enum WIDGET_NAME {
  SEARCH_DESKTOP = 'search-widget',
  SEARCH_MOBILE = 'search-mobile-widget',
  CHAT = 'chat-widget',
  CHAT_PRELOGIN = 'chat-widget-prelogin',
  NOTIFICATION = 'bell-notification-widget',
  LINKAGE_MINIAPP = 'linkage-miniapp'
}

export enum MINIAPP_SPA {
  LOGIN = 'login-identity',
  LINKAGE = 'linkage-miniapp',
  MINIAPP_IFRAME = 'miniapp-iframe',
  PORTAL_PAY = 'nab-portal-pay-miniapp',
  CREATE_PROFILE = 'create-profile'
}

export interface ActiveAppInstance extends AppInstance<XV2App> {
  app: { title?: string; printConfig?: { canPrint: boolean } } & XV2App;
}

export interface ISpaProps {
  activeAppInstance?: ActiveAppInstance;
  actions?: AppActions;
  routeError?: RouteError;
  initError?: Error;
  context?: ContextConfig;
}

export interface IGenericError {
  errorTitle?: string;
  errorDescription?: string;
}

export interface IAuthError {
  code?: any;
  httpStatusCode?: any;
  description?: any;
}

export interface CustomApp extends XV2App {
  title?: string;
}

export interface CustomSpaAppLoaderConfig extends SpaAppLoaderConfig {
  appMetaData: CustomApp;
}
